import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  GetRoomTypeAction,
  UpdateRoomTypeAction,
} from "../../../redux/actions/room_type/RoomTypeAction";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateRoomType = ({ rowData, basicRows, updateModal, toast, allCities }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.roomtype);
  const [hotelName, setHotelName] = useState(undefined);
  const [hotelNameAR, setHotelNameAR] = useState(undefined);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", hotelName ?? rowData.name);
    obj.append("name_ar", hotelNameAR ?? rowData.name_ar);
    let data = { id: rowData.id, obj: obj };

    dispatch(UpdateRoomTypeAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetRoomTypeAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="container">
      <form className="add-hotel" onSubmit={submitHandler}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              defaultValue={rowData.name}
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Hotel Name"
              onChange={(e) => setHotelName(e.target.value)}
            />
          </div>
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              defaultValue={rowData.name_ar}
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Hotel Name AR"
              onChange={(e) => setHotelNameAR(e.target.value)}
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateRoomType;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import {
  AddHotelAPI,
  DeleteHotelAPI,
  GetHotelsAPI,
  ShowHotelsAPI,
  UpdateHotelAPI,
} from "../../Api";

export const GetHotelAction = createAsyncThunk(
  "cms/hotel/get",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.get(
        GetHotelsAPI +
          info.page +
          "&size=" +
          info.size +
          "&isPaginate=" +
          info.isPaginate
      );
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const AddHotelAction = createAsyncThunk(
  "cms/hotel/add",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(AddHotelAPI, info);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const UpdateHotelAction = createAsyncThunk(
  "cms/hotel/update",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(
        UpdateHotelAPI + info.id,
        info.obj
      );
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const DeleteHotelAction = createAsyncThunk(
  "cms/hotel/delete",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.delete(DeleteHotelAPI + info);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const ShowHotelsAction = createAsyncThunk(
  "cms/hotels/show",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.get(`${ShowHotelsAPI}/${info.id}`);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

// export const baseURL = 'http://127.0.0.1:8000';
// export const baseURL = 'https://qashlaq.com/public/index.php';
export const baseURL =
   "https://back-end.blueocean.mirruba-jewellery.com/public/index.php";
  //"https://blueocean.sy/blue-ocean-back-end/public/index.php";
// export const imageURL = 'https://qashlaq.com/public';
export const imageURL =
   "https://back-end.blueocean.mirruba-jewellery.com/public";
  //"https://blueocean.sy/blue-ocean-back-end/public";
// 0946436022
//Statistics
export const GetStatisticsAPI = `${baseURL}/api/v1/cms/revenues/get`;
export const GetChartStatisticsAPI = `${baseURL}/api/v1/cms/charts/get`;
export const GetLineChartAPI = `${baseURL}/api/v1/cms/charts/line/get?type=`;

//Auth
export const loginAPI = `${baseURL}/api/v1/cms/login`;
export const logoutAPI = `${baseURL}/api/v1/cms/logout`;

//Notifications
export const GetNotificationsAPI = `${baseURL}/api/v1/cms/notifications/get/mine?page=`;
export const ReadNotificationAPI = `${baseURL}/api/v1/cms/notifications/pass/`;
export const PushNotificationAPI = `${baseURL}/api/v1/cms/notifications/push`;

//About Us
export const GetAboutUsAPI = `${baseURL}/api/v1/cms/about/get`;
export const PostAboutUsAPI = `${baseURL}/api/v1/cms/about/update`;

//Groups
export const GetGroupsAPI = `${baseURL}/api/v1/cms/groups?page=`;
export const AddGroupAPI = `${baseURL}/api/v1/cms/groups`;
export const UpdateGroupAPI = `${baseURL}/api/v1/cms/groups/`;
export const DeleteGroupAPI = `${baseURL}/api/v1/cms/groups/`;
export const ShowGroupAPI = `${baseURL}/api/v1/cms/groups/`;
export const AssignPermissionsAPI = `${baseURL}/api/v1/cms/groups/permissions/assignment/`;
export const GetPermissionsExceptAPI = `${baseURL}/api/v1/cms/permissions/except`;

//Car Categories
export const GetCarCategoriesAPI = `${baseURL}/api/v1/cms/cars/categories?page=`;
export const AddCarCategoriesAPI = `${baseURL}/api/v1/cms/cars/categories`;
export const UpdateCarCategoriesAPI = `${baseURL}/api/v1/cms/cars/categories/`;
export const DeleteCarCategoriesAPI = `${baseURL}/api/v1/cms/cars/categories/`;

//Car
export const GetCarsAPI = `${baseURL}/api/v1/cms/cars/categories/getCars/`;
export const AddCarAPI = `${baseURL}/api/v1/cms/cars/add/`;
export const UpdateCarAPI = `${baseURL}/api/v1/cms/cars/update/`;
export const DeleteCarAPI = `${baseURL}/api/v1/cms/cars/`;

//Admins
export const GetAdminsAPI = `${baseURL}/api/v1/cms/admins/get?page=`;
export const AddAdminAPI = `${baseURL}/api/v1/cms/admin/add`;
export const UpdateAdminAPI = `${baseURL}/api/v1/cms/admin/update/`;
export const DeleteAdminAPI = `${baseURL}/api/v1/cms/admin/remove/`;
export const ChangeAdminPasswordAPI = `${baseURL}/api/v1/cms/admin/password/change/`;

//Countries
export const GetCountriesAPI = `${baseURL}/api/v1/cms/countries/get?page=`;
export const SearchCountriesAPI = `${baseURL}/api/v1/cms/countries/search?page=`;
export const AddCountryAPI = `${baseURL}/api/v1/cms/cities/add`;
export const UpdateCountryAPI = `${baseURL}/api/v1/cms/cities/update/`;
export const DeleteCountryAPI = `${baseURL}/api/v1/cms/cities/remove/`;
export const GetCitiesByCountryAPI = `${baseURL}/api/v1/cms/cities/get/`;
export const GetAllCitiesAPI = `${baseURL}/api/v1/cms/cities/get`;

//Airports
export const GetAirportsByCityAPI = `${baseURL}/api/v1/cms/airports/get/`;
export const AddAirportAPI = `${baseURL}/api/v1/cms/airports/add`;
export const UpdateAirportAPI = `${baseURL}/api/v1/cms/airports/update/`;
export const DeleteAirportAPI = `${baseURL}/api/v1/cms/airports/remove/`;

//Visa
export const GetVisaAPI = `${baseURL}/api/v1/cms/visas/get?page=`;
export const AddVisaAPI = `${baseURL}/api/v1/cms/visas/add`;
export const UpdateVisaAPI = `${baseURL}/api/v1/cms/visas/update/`;
export const DeleteVisaAPI = `${baseURL}/api/v1/cms/visas/remove/`;

//Visa Types
export const GetVisaTypesAPI = `${baseURL}/api/v1/cms/visa/types/get?page=`;
export const AddVisaTypeAPI = `${baseURL}/api/v1/cms/visa/types/add`;
export const DeleteVisaTypeAPI = `${baseURL}/api/v1/cms/visa/types/remove/`;

//Visa Request
export const GetVisaRequestsAPI = `${baseURL}/api/v1/cms/visa/requests/get?page=`;
export const DoneVisaRequestsAPI = `${baseURL}/api/v1/cms/visa/request/done/`;
export const SuccessVisaRequestAPI = `${baseURL}/api/v1/cms/visa/request/success/`;
export const AbortVisaRequestAPI = `${baseURL}/api/v1/cms/visa/request/abort/`;
export const BookVisaRequestAPI = `${baseURL}/api/v1/cms/visa/request/book/`;
export const AddPriceVisaRequestAPI = `${baseURL}/api/v1/cms/visa/request/price/`;

//Travel Insurance
export const GetInsurancesAPI = `${baseURL}/api/v1/cms/insurances/get?page=`;
export const AddInsuranceAPI = `${baseURL}/api/v1/cms/insurances/add`;
export const UpdateInsuranceAPI = `${baseURL}/api/v1/cms/insurances/update/`;
export const DeleteInsuranceAPI = `${baseURL}/api/v1/cms/insurances/remove/`;

//Travel Insurance Requests
export const GetTravelInsuranceRequestsAPI = `${baseURL}/api/v1/cms/insurance/requests/get?page=`;
export const DoneTravelInsuranceRequestsAPI = `${baseURL}/api/v1/cms/insurance/request/done/`;
export const SuccessTravelInsuranceAPI = `${baseURL}/api/v1/cms/insurance/request/success/`;
export const AbortTravelInsuranceAPI = `${baseURL}/api/v1/cms/insurance/request/abort/`;
export const BookInsuranceRequestAPI = `${baseURL}/api/v1/cms/insurance/request/book/`;
export const AddPriceInsuranceRequestAPI = `${baseURL}/api/v1/cms/insurance/request/price/`;

//Transfer
export const GetTransfersAPI = `${baseURL}/api/v1/cms/transfers/get?page=`;
export const AddTransferAPI = `${baseURL}/api/v1/cms/transfers/add`;
export const UpdateTransferAPI = `${baseURL}/api/v1/cms/transfers/update/`;
export const DeleteTransferAPI = `${baseURL}/api/v1/cms/transfers/remove/`;
export const GetTransfersInRangeAPI = `${baseURL}/api/v1/cms/transfers/get/`;

//Transfer Request
export const GetTransferRequestsAPI = `${baseURL}/api/v1/cms/transfer/requests/get?page=`;
export const AddTransferSuggestAPI = `${baseURL}/api/v1/cms/transfer/suggests/add/`;
export const DoneTransferRequestAPI = `${baseURL}/api/v1/cms/transfer/request/done/`;
export const SuccessTransferRequestAPI = `${baseURL}/api/v1/cms/transfer/request/success/`;
export const AbortTransferRequestAPI = `${baseURL}/api/v1/cms/transfer/request/abort/`;
export const BookTransferRequestAPI = `${baseURL}/api/v1/cms/transfer/request/book/`;

//Hotels
export const GetHotelsAPI = `${baseURL}/api/v1/cms/hotels/get?page=`;
export const ShowHotelsAPI = `${baseURL}/api/v1/cms/hotels`;
export const AddHotelAPI = `${baseURL}/api/v1/cms/hotels/add`;
export const UpdateHotelAPI = `${baseURL}/api/v1/cms/hotels/update/`;
export const DeleteHotelAPI = `${baseURL}/api/v1/cms/hotels/remove/`;


// RoomType
export const GetRoomTypeAPI = `${baseURL}/api/v1/cms/room/types/get?page=`;
export const AddRoomTypeAPI = `${baseURL}/api/v1/cms/room/types/add`;
export const ExceptRoomTypeAPI = `${baseURL}/api/v1/cms/room/types/except`;
export const UpdateRoomTypeAPI = `${baseURL}/api/v1/cms/room/types/update/`;
export const DeleteRoomTypeAPI = `${baseURL}/api/v1/cms/room/types/remove/`;
export const AddHotelRoomTypeAPI=`${baseURL}/api/v1/cms/hotels/roomtypes/assignment`

//Hotels Request
export const GetHotelRequestsAPI = `${baseURL}/api/v1/cms/booking/hotel/get?page=`;
export const DoneHotelRequestsAPI = `${baseURL}/api/v1/cms/booking/hotel/done/`;
export const SuccessHotelRequestAPI = `${baseURL}/api/v1/cms/booking/hotel/success/`;
export const AbortHotelRequestAPI = `${baseURL}/api/v1/cms/booking/hotel/abort/`;
export const BookHotelRequestAPI = `${baseURL}/api/v1/cms/booking/hotel/booking/`;
export const AddPriceHotelRequestAPI = `${baseURL}/api/v1/cms/booking/hotel/price/`;
export const AddHotelSuggestAPI = `${baseURL}/api/v1/cms/booking/hotel/suggests/add/`;

//Flights Request
export const GetFlightRequestsAPI = `${baseURL}/api/v1/cms/flights/requests/get?page=`;
export const AddFlightSuggestAPI = `${baseURL}/api/v1/cms/flights/suggests/add/`;
export const DoneFlightRequestAPI = `${baseURL}/api/v1/cms/flights/request/done/`;
export const SuccessFlightRequestAPI = `${baseURL}/api/v1/cms/flights/success/`;
export const AbortFlightRequestAPI = `${baseURL}/api/v1/cms/flights/abort/`;
export const BookFlightRequestAPI = `${baseURL}/api/v1/cms/flights/booking/`;

//Feedback
export const GetFeedbacksAPI = `${baseURL}/api/v1/cms/feedbacks/get?page=`;

//Users
export const GetUsersAPI = `${baseURL}/api/v1/cms/users/get?page=`;
export const BlockUserAPI = `${baseURL}/api/v1/cms/users/block/`;
export const ShowUserAPI = `${baseURL}/api/v1/cms/users/show/`;
export const SearchUserAPI = `${baseURL}/api/v1/cms/users/search?phone_number=`;

//Offer
export const GetOffersAPI = `${baseURL}/api/v1/cms/offers/get?page=`;
export const NewOffersAPI = `${baseURL}/api/v1/cms/offers/add`;
export const DeleteOfferAPI = `${baseURL}/api/v1/cms/offers/delete/`;

//Log
export const GetVisaRequestsLogAPI = `${baseURL}/api/v1/cms/visa/logs/get?page=`;
export const GetInsuraceRequestsLogAPI = `${baseURL}/api/v1/cms/insurance/logs/get?page=`;
export const GetTransferRequestsLogAPI = `${baseURL}/api/v1/cms/transfer/logs/get?page=`;
export const GetHotelRequestsLogAPI = `${baseURL}/api/v1/cms/booking/logs/get?page=`;
export const GetFlightRequestsLogAPI = `${baseURL}/api/v1/cms/flights/logs/get?page=`;

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  AddRoomTypeAction,
  GetRoomTypeAction,
} from "../../../redux/actions/room_type/RoomTypeAction";
import { unwrapResult } from "@reduxjs/toolkit";

const AddRoomType = ({ basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.hotels);
  const [hotelName, setHotelName] = useState("");
  const [hotelNameAR, setHotelNameAR] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", hotelName);
    obj.append("name_ar", hotelNameAR);

    dispatch(AddRoomTypeAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetRoomTypeAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-hotel" onSubmit={submitHandler}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="RoomType Name"
              onChange={(e) => setHotelName(e.target.value)}
            />
          </div>
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="RoomType Name AR"
              onChange={(e) => setHotelNameAR(e.target.value)}
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddRoomType;

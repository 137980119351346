import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  GetAdminsAction,
  UpdateAdminAction,
} from "../../../redux/actions/AdminAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { convertSyrianPhoneNumber } from "../../../utils/Formatters";

const UpdateAdmin = ({ rowData, basicRows, updateModal, toast, groups }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.admins);
  const [fname, setFname] = useState(undefined);
  const [lname, setLname] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(rowData.group);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    // console.log(rowData);
    // console.log(selectedGroup);
    console.log(rowData.phone_number);
    obj.append("first_name", fname ?? rowData.first_name);
    obj.append("last_name", lname ?? rowData.last_name);
    obj.append("phone_number", phoneNumber ?? "+" + rowData.phone_number);
    // obj.append(
    //   "phone_number",
    //   convertSyrianPhoneNumber(phoneNumber) ?? "+" + rowData.phone_number
    // );
    obj.append("group_id", selectedGroup.id);

    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateAdminAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetAdminsAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.first_name}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="First Name"
            onChange={(e) => setFname(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.last_name}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Last Name"
            onChange={(e) => setLname(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.phone_number}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>

        <Dropdown
          valueTemplate={selectedGroup.name}
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.value)}
          options={groups}
          optionLabel="name"
          placeholder="Select a Group"
        />

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateAdmin;

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  AddHotelAction,
  GetHotelAction,
} from "../../../redux/actions/hotel/HotelAction";
import { Rating } from "primereact/rating";
import { unwrapResult } from "@reduxjs/toolkit";
import { Checkbox } from "primereact/checkbox";

const AddHotel = ({ basicRows, createModal, toast, allCities }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.hotels);
  const [hotelName, setHotelName] = useState("");
  const [hotelNameAR, setHotelNameAR] = useState("");
  const [checkedbreakfast, setCheckedbreakfast] = useState(false);
  const [rate, setRate] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [hotelUrl, setHotelUrl] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", hotelName);
    obj.append("name_ar", hotelNameAR);
    if (checkedbreakfast) {
      obj.append("breakfast", 1);
    } else {
      obj.append("breakfast", 0);
    }
    obj.append("hotel_rate", rate);
    // obj.append("hotel_site", hotelUrl);
    obj.append("city_id", selectedCity.id);

    dispatch(AddHotelAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetHotelAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  const groupedItemTemplate = (option) => {
    return (
      <div className="country">
        <h3>{option.name}</h3>
      </div>
    );
  };

  return (
    <div className="container">
      <form className="add-hotel" onSubmit={submitHandler}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Hotel Name"
              onChange={(e) => setHotelName(e.target.value)}
            />
          </div>
          <div
            className="input-wrapper"
            style={{ width: "49%", marginBottom: "5px" }}
          >
            <InputText
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Hotel Name AR"
              onChange={(e) => setHotelNameAR(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="url"
            placeholder="Hotel URL"
            onChange={(e) => setHotelUrl(e.target.value)}
          />
        </div> */}
        <div className="input-wrapper">
          <label
            style={{
              marginRight: "10px",
            }}
            htmlFor="ingredient1"
          >
            Break fast ?
          </label>
          <Checkbox
            inputId="ingredient1"
            onChange={(e) => setCheckedbreakfast(e.checked)}
            checked={checkedbreakfast}
          ></Checkbox>

          {/* <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Break fast"
            onChange={(e) => setBreakfast(e.target.value)}
          /> */}
        </div>

        <div className="input-wrapper">
          <h4>Select a city</h4>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.value);
            }}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>

        <div className="input-wrapper">
          <h4>Select a rate</h4>
          <Rating
            value={rate}
            onChange={(e) => setRate(e.value)}
            cancel={false}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddHotel;

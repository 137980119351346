import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import {
  AddRoomTypeAPI,
  DeleteRoomTypeAPI,
  GetRoomTypeAPI,
  UpdateRoomTypeAPI,
  AddHotelRoomTypeAPI,
  ExceptRoomTypeAPI
} from "../../Api";

export const GetRoomTypeAction = createAsyncThunk(
  "cms/room/types/get",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.get(
        GetRoomTypeAPI +
          info.page +
          "&size=" +
          info.size +
          "&isPaginate=" +
          info.isPaginate
      );
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);
export const ExceptRoomTypeAction = createAsyncThunk(
  "cms/room/types/except",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(ExceptRoomTypeAPI, info);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const AddRoomTypeAction = createAsyncThunk(
  "cms/room/types/add",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(AddRoomTypeAPI, info);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const UpdateRoomTypeAction = createAsyncThunk(
  "cms/room/types/update",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(
        UpdateRoomTypeAPI + info.id,
        info.obj
      );
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const DeleteRoomTypeAction = createAsyncThunk(
  "cms/room/types/remove",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.delete(DeleteRoomTypeAPI + info);
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const AddHotelRoomTypeAction = createAsyncThunk(
  "cms/hotels/roomtypes/assignment",
  async (info, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(
       `${AddHotelRoomTypeAPI}/${info.id}` ,
        info.obj
      );
      return data;
    } catch (error) {
      if (error.response) {
        const validationMessage = error.response.data.message;
        return rejectWithValue(validationMessage);
      } else {
        return rejectWithValue(errorMessage);
      }
    }
  }
);

import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddHotelRoomTypeAction,
  ExceptRoomTypeAction,
  GetRoomTypeAction,
} from "../../../redux/actions/room_type/RoomTypeAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess, showWarn } from "../../../utils/ToastService";
import { roomtypeUnmount } from "../../../redux/reducers/room_type/RoomTypeReducer";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ShowHotel = ({ toast }) => {
  const [formData, setFormData] = useState([]);
  const [formCount, setFormCount] = useState(1);
  // const { loading } = useSelector((state) => state.roomType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roomType, setRoomType] = useState([]);
  const [dataRoomType, setDataRoomType] = useState([]);
  const [selectedRoomType, setselectedRoomType] = useState([]);
  const [deleteR, setDeleteR] = useState([]);
  const param = useParams();
  const loction = useLocation();
  const handleAddForm = () => {
    setFormCount(formCount + 1);
    const newForm = {
      id: formCount + 1,
      data: "",
    };
    setFormData([...formData, newForm]);
  };
  const handleRemoveForm = (id) => {
    const updatedFormData = formData.filter((form) => form.id !== id);
    setFormData(updatedFormData);
  };
  const handleInputChange = async (e, formId, inputName) => {
    const { value } = e.target || e;
    // console.log(value);
    const updatedFormData = formData.map((form) => {
      if (form.id === formId) {
        return {
          ...form,
          data: {
            ...form.data,
            [inputName]: value,
          },
        };
      }
      return form;
    });

    setFormData(updatedFormData);
  };
  const handleMultiSelectChange = (e) => {
    const selectedList = e.value;
    const removedItem = selectedRoomType?.filter(
      (item) => !selectedList?.includes(item)
    );
    setselectedRoomType(selectedList);
    setDeleteR((prevDeletedPermissions) => [
      ...prevDeletedPermissions,
      ...removedItem,
    ]);
  };
  useEffect(() => {
    const filteredObjects = loction?.state?.dataRoomType.map(
      ({ name, id }) => ({
        name,
        id,
      })
    );
    setDataRoomType(filteredObjects);
    const filteredSelectedObjects = loction?.state?.dataRoomType.map(
      ({ name, id }) => ({
        name,
        id,
      })
    );
    setselectedRoomType(filteredSelectedObjects);

    const newFormData = new FormData();
    loction?.state?.dataRoomType.forEach((form, i) => {
      newFormData.append(`except[${i}]`, form?.id || "");
    });
    dispatch(ExceptRoomTypeAction(newFormData))
      .then(unwrapResult)
      .then((payload) => {
        const filteredObjects = payload.data.map(({ name, id }) => ({
          name,
          id,
        }));
        setRoomType(filteredObjects);
      })
      .catch((error) => {
        showError(error, toast);
      });
    return () => {
      dispatch(roomtypeUnmount());
    };
  }, [dispatch, toast]);

  const handleSubmit = () => {
    const formattedFormData = formData.map((form) => {
      const newData = { ...form.data };

      return {
        ...form,
        data: newData,
      };
    });

    const isAnyFieldEmpty = formattedFormData.some((form) => {
      return (
        !form.data["id"] ||
        !form.data["room_number"] ||
        !form.data["room_capacity"]
      );
    });

    if (isAnyFieldEmpty) {
      showWarn("Please fill in all fields before submitting.", toast);
      return;
    }

    const newFormData = new FormData();
    formattedFormData.forEach((form, i) => {
      newFormData.append(`room_types[${i}][id]`, form?.data?.id?.id || "");
      newFormData.append(
        `room_types[${i}][room_number]`,
        form.data["room_number"] || ""
      );
      newFormData.append(
        `room_types[${i}][room_capacity]`,
        form.data["room_capacity"] || ""
      );
    });

    console.log("newFormData", newFormData);
    let info = {
      id: param.Rooms_TypeId,
      obj: newFormData,
    };

    dispatch(AddHotelRoomTypeAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        navigate(-1);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  const handleDeleteSubmit = () => {
    if (deleteR.length === 0) {
      showWarn(
        "Please select the field you want to delete before submitting.",
        toast
      );
      return;
    }

    const newFormData = new FormData();
    deleteR.forEach((form, i) => {
      newFormData.append(`delete_room_types[${i}]`, form?.id || "");
    });

    console.log("newFormData", newFormData);
    let info = {
      id: param.Rooms_TypeId,
      obj: newFormData,
    };

    dispatch(AddHotelRoomTypeAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        navigate(-1);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div
      className="show-Hotels"
      style={{
        textAlign: "end",
        padding: "10px 40px",
      }}
    >
      {loction?.state?.dataRoomType.length !== 0 && (
        <div style={{ textAlign: "start", marginTop: "10px" }}>
          <h1>Hotel room type Details :</h1>
          {loction?.state?.dataRoomType.map((item, index) => {
            return (
              <div style={{ marginTop: "20px" }} key={index}>
                <p
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    fontWeight: "600",
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {index + 1 + " - "}
                  </span>
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#26a7df",
                      fontWeight: "500",
                    }}
                  >
                    Room Type Name
                  </span>
                  : {item.name} ,{" "}
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#26a7df",
                      fontWeight: "500",
                    }}
                  >
                    Room Capacity
                  </span>{" "}
                  : {item?.room_type_hotel?.room_capacity} ,
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#26a7df",
                      fontWeight: "500",
                    }}
                  >
                    Room Number
                  </span>{" "}
                  , {item?.room_type_hotel?.room_number}
                </p>
              </div>
            );
          })}
        </div>
      )}
      <div>
        {formData.map((form, index) => (
          <div key={form.id} className="add-transfer-suggest">
            <form className="suggest-form">
              <div className="header">
                <h4 className="suggest-number">
                  Add Room Type To Hotel : {index + 1}
                </h4>
                <div className="cancel">
                  <Button
                    size="small"
                    icon="bi bi-x-lg"
                    className="p-button-rounded p-button-text p-button-danger"
                    type="button"
                    onClick={() => handleRemoveForm(form.id)}
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <InputNumber
                  className="custom-number-input"
                  required
                  value={formData["room_number"]}
                  placeholder="Room Number"
                  onChange={(e) => handleInputChange(e, form.id, "room_number")}
                />
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="custom-number-input"
                  required
                  value={formData["room_capacity"]}
                  placeholder="Room Capacity"
                  onChange={(e) =>
                    handleInputChange(e, form.id, "room_capacity")
                  }
                />
              </div>
              <div className="input-wrapper">
                {/* <h4>Select a hotel</h4> */}
                <Dropdown
                  // appendTo={document.querySelector(".container")}
                  value={form.data["id"]}
                  onChange={(e) => {
                    // setSelectedCity(e.value);
                    handleInputChange(e, form.id, "id");
                  }}
                  options={roomType}
                  optionLabel="name"
                  // optionGroupLabel="name"
                  // optionGroupChildren="cities"
                  // optionGroupTemplate={groupedItemTemplate}
                  placeholder="Select a RoomType"
                  filter
                />
              </div>
            </form>
          </div>
        ))}
      </div>

      <div
        className="actions"
        style={{
          textAlign: formData.length === 0 ? "center" : "end",
          marginBottom: formData.length === 0 ? "20px" : "",
          marginTop: formData.length === 0 ? "" : "-30px",
          marginRight: formData.length === 0 ? "" : "40px",
        }}
      >
        <Button
          size="small"
          className="warning"
          onClick={handleAddForm}
          label={"Add Room Type To Hotel"}
          type="button"
        />
      </div>
      {formData.length !== 0 && (
        <div
          className="done-btn"
          style={{ textAlign: "center", marginBottom: "30px" }}
        >
          <Button
            // loading={loading}
            label="Submit Room Type To Hotel"
            type="button"
            size="small"
            className="info"
            aria-label="Submit"
            onClick={handleSubmit}
          />
        </div>
      )}
      <div style={{ textAlign: "center" }}>
        <h2
          className="select-header"
          style={{
            marginBottom: "20px",
          }}
        >
          Change Room Type In Hotel
        </h2>
        <MultiSelect
          appendTo={"self"}
          showSelectAll={false}
          value={selectedRoomType}
          options={dataRoomType}
          onChange={handleMultiSelectChange}
          optionLabel="name"
          placeholder="Select Room Type in Hotel"
          filter
          showClear
        />

        <div className="actions">
          <Button
            size="small"
            className="info"
            label="Delete"
            type="submit"
            style={{ backgroundColor: "brown" }}
            onClick={handleDeleteSubmit}
            // loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default ShowHotel;

import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import RoomsTypeDT from "./Rooms_TypeDT";
import AddRoomType from "./actions/AddRoomType";
import DeleteRoomType from "./actions/DeleteRoomType";
import UpdateRoomType from "./actions/UpdateRoomType";

const Rooms_Type = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);

  return (
    <div className="sticky-container">
      <RoomsTypeDT
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add RoomType"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
        // onShow={getDataHandler}
      >
        <AddRoomType
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update RoomType"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateRoomType
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete RoomType"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteRoomType
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};
export default Rooms_Type;

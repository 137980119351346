import { createSlice } from "@reduxjs/toolkit";
import {
  AddRoomTypeAction,
  AddHotelRoomTypeAction,
  DeleteRoomTypeAction,
  GetRoomTypeAction,
  UpdateRoomTypeAction,
  ExceptRoomTypeAction,
} from "../../actions/room_type/RoomTypeAction";
export const RoomTypeReducer = createSlice({
  name: "roomtype Reducer",
  initialState: {
    loading: false,
    totalItems: "",
    currentPage: "",
    roomtype: [],
  },
  reducers: {
    roomtypeUnmount: (state) => {
      state.roomtype = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
        try {
          if (payload.data.data === undefined) {
            state.roomtype = payload.data;
          } else {
            state.roomtype = payload.data.data;
          }
          state.totalItems = payload.data.total;
          state.currentPage = payload.data.current_page;
        } catch (err) {
          // console.log(err)
        }
      })
      .addCase(GetRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })

      .addCase(AddRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })
      .addCase(AddRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })

      .addCase(ExceptRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(ExceptRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })
      .addCase(ExceptRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })

      .addCase(UpdateRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })
      .addCase(UpdateRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })

      .addCase(DeleteRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })
      .addCase(DeleteRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })

      .addCase(AddHotelRoomTypeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddHotelRoomTypeAction.fulfilled, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      })
      .addCase(AddHotelRoomTypeAction.rejected, (state, { payload }) => {
        // console.log(payload)
        state.loading = false;
      });
  },
});
export default RoomTypeReducer.reducer;
export const { roomtypeUnmount } = RoomTypeReducer.actions;

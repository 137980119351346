import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess, showWarn } from "../../../utils/ToastService";
import { InputSwitch } from "primereact/inputswitch";
import { useNavigate } from "react-router-dom";
import { GetHotelAction } from "../../../redux/actions/hotel/HotelAction";
import { AddHotelSuggestAction } from "../../../redux/actions/hotel/HotelRequestAction";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "../../../assets/pages/transfer-request/AddTransferSuggest.scss";
import { unwrapResult } from "@reduxjs/toolkit";
import { Dropdown } from "primereact/dropdown";

const AddHotelSuggest = ({ id, toast, isValid }) => {
  const [formData, setFormData] = useState([]);
  const [formCount, setFormCount] = useState(1);
  const { loading, hotelRequests } = useSelector(
    (state) => state.hotelRequests
  );
  const [price, setPrice] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const handleAddForm = () => {
    setFormCount(formCount + 1);
    const newForm = {
      id: formCount + 1,
      data: "",
    };
    setFormData([...formData, newForm]);
  };
  useEffect(() => {
    dispatch(
      GetHotelAction({
        isPaginate: 0,
      })
    )
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, toast]);

  const handleRemoveForm = (id) => {
    const updatedFormData = formData.filter((form) => form.id !== id);
    setFormData(updatedFormData);
  };

  const handleInputChange = async (e, formId, inputName) => {
    const { value } = e.target || e;
    // console.log(value);
    const updatedFormData = formData.map((form) => {
      if (form.id === formId) {
        return {
          ...form,
          data: {
            ...form.data,
            [inputName]: value,
          },
        };
      }
      return form;
    });

    setFormData(updatedFormData);
  };
  const handleSubmit = () => {
    const formattedFormData = formData.map((form) => {
      const newData = { ...form.data };

      return {
        ...form,
        data: newData,
      };
    });

    const isAnyFieldEmpty = formattedFormData.some((form) => {
      return !form.data["notes"] || !form.data["hotel_id"] || !form.data["price"];
    });

    if (isAnyFieldEmpty) {
      showWarn("Please fill in all fields before submitting.", toast);
      return;
    }

    const newFormData = new FormData();
    formattedFormData.forEach((form, i) => {
      // form.data.hotel_id.hotel.id
      // newFormData.append(`suggests[${i}][hotel_id]`, form.data.hotel || "");
      newFormData.append(`suggests[${i}][hotel_id]`, form.data.hotel_id.hotel.id || "");
      newFormData.append(`suggests[${i}][price]`, form.data["price"] || "");
      newFormData.append(`suggests[${i}][notes]`, form.data["notes"] || "");
    });

    newFormData.append("is_valid", checked ? "0" : "1");
    if (price !== undefined) {
      newFormData.append("price", price ?? price);
    }
console.log("newFormData",newFormData);
    let info = {
      id: id,
      obj: newFormData,
    };

    dispatch(AddHotelSuggestAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        navigate(-1);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div>
      <div>
        {formData.map((form, index) => (
          <div key={form.id} className="add-transfer-suggest">
            <form className="suggest-form">
              <div className="header">
                <h4 className="suggest-number">Suggest number: {index + 1}</h4>
                <div className="cancel">
                  <Button
                    size="small"
                    icon="bi bi-x-lg"
                    className="p-button-rounded p-button-text p-button-danger"
                    type="button"
                    onClick={() => handleRemoveForm(form.id)}
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <InputNumber
                  className="custom-number-input"
                  required
                  value={formData["price"]}
                  placeholder="Hotel Price"
                  onChange={(e) => handleInputChange(e, form.id, "price")}
                />
              </div>

              {/* <div className="input-wrapper">
                <InputText
                  unstyled
                  className="custom-input"
                  value={form.data["hotel"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "hotel")}
                  placeholder="Hotel Name"
                />
              </div> */}
              <div className="input-wrapper">
                {/* <h4>Select a hotel</h4> */}
                <Dropdown
                  // appendTo={document.querySelector(".container")}
                  value={form.data["hotel_id"]}
                  onChange={(e) => {
                    // setSelectedCity(e.value);
                    handleInputChange(e, form.id, "hotel_id")
                  }}
                  options={hotelRequests}
                  optionLabel="hotel.name"
                  // optionGroupLabel="name"
                  // optionGroupChildren="cities"
                  // optionGroupTemplate={groupedItemTemplate}
                  placeholder="Select a Hotel"
                  filter
                />
              </div>
              <div className="input-wrapper">
                <InputTextarea
                  className="custom-input"
                  autoResize
                  value={form.data["notes"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "notes")}
                  placeholder="Notes"
                />
              </div>
            </form>
          </div>
        ))}
        {isValid === null && (
          <div className="check-btn">
            <h4>Valid</h4>
            <InputSwitch
              checked={checked}
              onChange={(e) => setChecked(e.value)}
            />
            <h4>Invalid</h4>
          </div>
        )}
        {isValid === null && !checked && (
          <div className="input-wrapper">
            <InputNumber
              className="custom-number-input"
              required
              value={price}
              placeholder="Booking Price"
              onChange={(e) => setPrice(e.value)}
            />
          </div>
        )}

        <div className="actions">
          <Button
            size="small"
            className="warning"
            onClick={handleAddForm}
            label={"Add Suggest"}
            type="button"
          />
        </div>
        <div className="done-btn">
          <Button
            loading={loading}
            label="Submit Suggestions"
            type="button"
            size="small"
            className="info"
            aria-label="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AddHotelSuggest;

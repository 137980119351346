import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { LogInAction } from "../../redux/actions/AuthAction";
import { showError, showSuccess } from "../../utils/ToastService";
import { convertSyrianPhoneNumber } from "../../utils/Formatters";
import { unwrapResult } from "@reduxjs/toolkit";

const LoginForm = ({ toast }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    let data = new FormData();
    let fcmToken = localStorage.getItem("fcmToken");

    // data.append("phone_number", convertSyrianPhoneNumber(phoneNumber));
    data.append("phone_number", phoneNumber);
    data.append("password", password);
    fcmToken && data.append("fcm_token", fcmToken);

    dispatch(LogInAction(data))
      .then(unwrapResult)
      .then((payload) => {
        // console.log(payload);
        showSuccess(payload.message, toast);
        const permissions = payload?.data?.permission?.map((item) => item.name);
        localStorage.setItem("permission", permissions);
        localStorage.setItem("group", payload.data.group.name);
        localStorage.setItem(
          "username",
          `${payload.data.user.f_name} ${payload.data.user.l_name}`
        );
        localStorage.setItem("phone", payload.data.user.phone_number);
        if (payload.data?.group?.name === "super-admin")
          localStorage.setItem("isSuper", true);
        else {
          localStorage.setItem("isSuper", false);
        }
        window.location.href = "/welcome";
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="login-form animate__animated animate__pulse">
      <div className="logo">
        <img alt="blue ocean" src={"./Images/oldLogo.png"} />
      </div>
      <form onSubmit={handleLogin}>
        <div className="input-wrapper">
          <h5>Phone Number</h5>
          <InputText
            autoComplete="off"
            minLength={10}
            // maxLength={13}
            required
            unstyled
            className="custom-input"
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number +963xxxxxxxxx"
          ></InputText>
        </div>
        <div className="input-wrapper">
          <h5>Password</h5>
          <InputText
            autoComplete="off"
            required
            unstyled
            className="custom-input"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <Button size="small" className="info" loading={loading} type="submit">
          Log in
        </Button>
      </form>
      <div className="fcm-warning" id="fcm-warning"></div>
      <div id="notification-permission"></div>
    </div>
  );
};

export default LoginForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";
import { GetRoomTypeAction } from "../../redux/actions/room_type/RoomTypeAction";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const Rooms_TypeDT = ({ createModal, deleteModal, updateModal, toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, roomtype } = useSelector((state) => state.roomtype);
  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };
  console.log(roomtype);
  useEffect(() => {
    let info = { page: currentPage, size: paginatorSize, isPaginate: 1 };
    dispatch(GetRoomTypeAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);

  const header = (
    <div className="header">
      <span className="title">Rooms Type</span>
      <Button
        size="small"
        icon="pi pi-plus"
        onClick={() => {
          createModal(true, undefined, basicRows);
        }}
        rounded
        text
        raised
        aria-label="Favorite"
      />
    </div>
  );
  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            deleteModal(true, rowData, basicRows);
          }}
        />
        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
          onClick={() => {
            updateModal(true, rowData, basicRows);
          }}
        />
      </>
    );
  };

  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={roomtype}>
        <Column field="name" header="Name"></Column>
        <Column field="name_ar" header="NameAR"></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};
export default Rooms_TypeDT;

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  AddAdminAction,
  GetAdminsAction,
} from "../../../redux/actions/AdminAction";
import { convertSyrianPhoneNumber } from "../../../utils/Formatters";
import { unwrapResult } from "@reduxjs/toolkit";

const AddAdmin = ({ basicRows, createModal, groups, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.admins);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("first_name", fname);
    obj.append("last_name", lname);
    // obj.append("phone_number", convertSyrianPhoneNumber(phoneNumber));
    obj.append("phone_number", phoneNumber);
    obj.append("password", password);
    obj.append("group_id", selectedGroup.id);

    dispatch(AddAdminAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetAdminsAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="First Name"
            onChange={(e) => setFname(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Last Name"
            onChange={(e) => setLname(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Phone Number +963xxxxxxxxx"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <Dropdown
          required
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.value)}
          options={groups}
          optionLabel="name"
          placeholder="Select a group"
        />

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddAdmin;

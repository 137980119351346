import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/AuthReducer";
import GroupReducer from "./reducers/GroupReducer";
import CarGategoryReducer from "./reducers/car/CarGategoryReducer";
import CarReducer from "./reducers/car/CarReducer";
import AdminReducer from "./reducers/AdminReducer";
import CountryReducer from "./reducers/CountryReducer";
import AirportReducer from "./reducers/AirportReducer";
import VisaReducer from "./reducers/visa/VisaReducer";
import InsuranceReducer from "./reducers/insurance/InsuranceReducer";
import TransferReducer from "./reducers/transfer/TransferReducer";
import HotelReducer from "./reducers/hotel/HotelReducer";
import TransferRequestReducer from "./reducers/transfer/TransferRequestReducer";
import VisaRequestReducer from "./reducers/visa/VisaRequestReducer";
import InsuranceRequestsReducer from "./reducers/insurance/InsuranceRequestsReducer";
import HotelRequestReducer from "./reducers/hotel/HotelRequestReducer";
import FlightRequestReducer from "./reducers/FlightRequestReducer";
import FeedbackReducer from "./reducers/FeedbackReducer";
import OfferReducer from "./reducers/OfferReducer";
import UserReducer from "./reducers/UserReducer";
import AboutUsReducer from "./reducers/AboutUsReducer";
import NotificationsReducer from "./reducers/NotificationsReducer.";
import StatisticsReducer from "./reducers/StatisticsReducer";
import RoomTypeReducer from "./reducers/room_type/RoomTypeReducer";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    group: GroupReducer,
    statistics: StatisticsReducer,
    cars: CarReducer,
    about: AboutUsReducer,
    users: UserReducer,
    admins: AdminReducer,
    carCategory: CarGategoryReducer,
    airports: AirportReducer,
    countries: CountryReducer,
    visas: VisaReducer,
    visaRequest: VisaRequestReducer,
    insurances: InsuranceReducer,
    insurancesRequests: InsuranceRequestsReducer,
    transfers: TransferReducer,
    transferRequests: TransferRequestReducer,
    hotels: HotelReducer,
    roomtype: RoomTypeReducer,
    hotelRequests: HotelRequestReducer,
    flightRequests: FlightRequestReducer,
    feedback: FeedbackReducer,
    offers: OfferReducer,
    notifications: NotificationsReducer,
  },
});
export default store;
